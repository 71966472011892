<template>
  <div class="text-container"></div>
</template>
<script setup></script>
<style lang="less" scoped>
.text-container {
  // width: 200px;
  // height: 30px;
  // line-height: 30px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // border-radius: 10px;
  border-radius: 5px;
}
.text-container:empty {
  /* 内容区域空状态时的样式 */
  background-color: #f2f2f2;
}
.text-container:empty::after {
  /* 内容区域空状态时追加骨架屏样式 */
  content: '';
  display: block;
  // width: 200px;
  // height: 30px;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(225, 225, 225, 0.753),
    transparent
  );
  animation: loading 2.5s infinite;
}

@keyframes loading {
  /* 骨架屏的动画 */
  100% {
    transform: translateX(100%);
  }
}
</style>
