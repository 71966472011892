<template>
  <div class="attractCardBox">
    <div class="attractCard">
      <div class="tit">期待与这样的您合作</div>
      <div class="conList">
        <div class="conItem">
          <div class="img">
            <img
              src="https://video.wahool.com/b_website/home/attrIcon1.png"
              alt=""
            />
          </div>
          <div class="itemTit">四大品类</div>
          <div class="descBox">
            <div class="descItem">品类多涉及特型女装</div>
            <div class="descItem">服饰配饰、鞋类箱包、家居百货</div>
          </div>
        </div>
        <div class="conItem">
          <div class="img">
            <img
              src="https://video.wahool.com/b_website/home/attrIcon2.png"
              alt=""
            />
          </div>
          <div class="itemTit">基建完备</div>
          <div class="descBox">
            <div class="descItem">具有完善</div>
            <div class="descItem">人员与工厂配置</div>
          </div>
        </div>
        <div class="conItem">
          <div class="img">
            <img
              style="width: 102px; height: 120px"
              src="https://video.wahool.com/b_website/home/attrIcon3.png"
              alt=""
            />
          </div>
          <div class="itemTit">供应链稳定</div>
          <div class="descBox">
            <div class="descItem">具备质量控制和仓储备货能力</div>
            <div class="descItem">货源充足，供货稳定</div>
          </div>
        </div>
        <div class="conItem">
          <div class="img">
            <img
              src="https://video.wahool.com/b_website/home/attrIcon4.png"
              alt=""
            />
          </div>
          <div class="itemTit">研发创新</div>
          <div class="descBox">
            <div class="descItem">有自主研发和设计能力</div>
            <div class="descItem">新品>10款/月</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
@media screen and(min-width:1200px) and (max-width: 1440px) {
  .attractCard {
    padding: 0 4%;
  }
}
@media screen and(min-width:1440px) {
  .attractCard {
    padding: 0 10%;
  }
}
.attractCardBox {
  width: 100%;
  padding: 100px 0;
  background: rgba(237, 237, 237, 0.5);
  display: flex;
  justify-content: center;
  font-family: 'Lantinghei SC';
  .attractCard {
    // min-width: 1350px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tit {
      font-family: 'Lantinghei SC';
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #111111;
    }
    .conList {
      margin-top: 80px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .conItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          width: 220px;
          height: 220px;
          background: #ffffff;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: flex;
          }
        }

        .itemTit {
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          text-align: center;
          color: #fb5d43;
          mix-blend-mode: normal;
          margin-top: 20px;
          font-family: 'Lantinghei SC';
        }
        .descBox {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .descItem {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            color: #111111;
          }
        }
      }
      .conItem :nth-child(1) .img img {
        width: 91px;
        height: 120px;
      }
      .conItem :nth-child(2) .img img {
        width: 103px;
        height: 110px;
      }
      .conItem :nth-child(3) .img img {
        width: 102px;
        height: 120px;
      }
      // .conItem :nth-child(4) .img img {
      //   width: 103px;
      //   height: 120px;
      // }
    }
  }
}
</style>
