<template>
  <div class="homeBanner">
    <a-carousel arrows>
      <template #prevArrow>
        <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
          <left-circle-outlined />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-slick-arrow" style="right: 10px">
          <right-circle-outlined />
        </div>
      </template>
      <div class="bannerItem">
        <skeleton v-show="flag" />
        <img
          @load="loadSuccess"
          src="https://video.wahool.com/b_website/live/supplierBanner.png"
          alt=""
        />
      </div>
    </a-carousel>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import skeleton from '@/components/skeleton.vue';
const flag = ref(true);
const loadSuccess = () => {
  flag.value = false;
};
</script>
<style lang="less" scoped>
.bannerItem {
  width: 100%;
  position: relative;
  min-height: 900px;
  // background: url('https://video.wahool.com/b_website/live/supplierBanner.png')
  //   no-repeat;
  // background-position: center center;
  // background-size: cover;
  img {
    width: 100%;
    height: 900px;
    object-fit: cover;
    display: inline-block;
  }
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  // height: 160px;
  // line-height: 160px;
  // background: #364d79;
  overflow: hidden;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
</style>
